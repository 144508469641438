<template>
    <div class="stock-take">
        <h1>Stock Take</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: "StockTakeView",
    components: {},
  };
  </script>
  